.channelTab {
  margin-bottom: 0.33em;
  text-align: center;
  color: white;
  background-color: #888;
  border: 2px solid #111;
}

.channelTab > p {
  margin: 0.33em 0;
}

.channelTab:hover {
  cursor: pointer;
  background-color: #999;
  border-color: #222
}

.channelTab.selected {
  background-color: #cecece;
  color: black;
  border: 2px solid white;
  font-weight: bold;
}

.channelTab.selected:hover {
  background-color: #cecece;
  cursor: default;
}

.channelThumb {
  width: 100%;
}

@media(max-width:599px) {
  .channelTab {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0.33em;
  }
  .channelTab > p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .channelThumb {
    display: none;
  }
}
