#video-section {
  display: flex;
  flex-grow: 2;
  flex-basis: 0;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: black;
}

#video-section.nocursor {
  cursor: none;
}
