#browsing-hide-button {
  flex-grow: 1;
  background-color: hsl(
    var(--global-color-hue, 213),
    calc(var(--global-color-saturation, 80%) - 1%),
    calc(var(--global-color-value, 34%) - 9%)
  );
  border: 1px solid black;
  color: white;
  font-size: large;
}

#browsing-hide-button:focus,
#browsing-hide-button:active {
  outline: none;
}

#browsing-hide-button:hover {
  cursor: pointer;
}
