.browsing-item {
  width: 120px;
  padding: 11.5px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
}

.browsing-item.playing {
  background-color: #1359C3;
  color: white;
}

.browsing-item-icon {
  max-width: 120px;
  max-height: 67.5px;
  width: auto;
  height: auto;
}

.browsing-item-icon-container {
  width:120px;
  height:67.5px;
  margin-bottom: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:black;
}

.browsing-item-title,
.browsing-item-description {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.browsing-item-title {
  font-weight: bold;
}

.browsing-item-description {
  font-size: 11px;
}

.browsing-item-info-button {
  float: right;
}
