#listwrapper {
  display: flex;
  flex-grow: 1;
  height: 0;
}

#scheduleContainer {
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  flex-grow: 1;
  width: 0;
  background-color: #d5d5d5;
}

@media(max-width:599px) {
  #listwrapper {
    flex-direction: column;
  }
  #scheduleContainer {
    width: initial;
    height: 0;
  }
}
