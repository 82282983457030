.video-agenda-item {
  margin-top: 0.5em;
}

.video-agenda-item-contents:hover {
  cursor: default;
  background-color: #b7b7b7;
}

.video-agenda-item-contents.current,
.video-agenda-item-contents.current:hover {
  background-color: #1359C3;
  color: white;
}

.video-agenda-item-description,
.video-agenda-item-links {
  font-size: small;
}

.video-agenda-item-time {
  display: inline-block;
  float: right;
  padding-right: 0.5em;
}

.video-agenda-item-title {
  display: inline-block;
}

.video-agenda-item-contents,
.video-agenda-item-links {
  padding-left: 0.5em;
}

.video-agenda-item-children {
  padding-left: 1em;
}
