.video-display {
  background-color: black;
  flex-grow: 2;
  max-height: 100%;
  height: 0;
  width: 100%;
}

.video-display.full {
  width: 100%;
  height: 100%;
}

iframe.video-display {
  border: none;
}
