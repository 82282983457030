html {
  height: 100%;
  background-color: black;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Verdana",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  height: 100%;
}

div.container.text-center {
  height: 100%;
}

#wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: sans-serif;
}
@media(min-aspect-ratio: 3/2) {
  #wrapper.schedule {
    flex-direction: row;
  }
}

span {
  margin: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

thead > tr {
  border: none;
}

th, tr {
  border: solid 1px black;
}

th, td {
  padding: 0;
}

td {
  border: solid 2px black;
}

button {
  padding: 0;
  border: 0;
  background: none;
}

button:focus,
button:active {
  outline: none;
}

button:hover {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

div.noselect:hover,
span.noselect:hover{
  cursor: default;
}

input,
textarea {
  background-color: #fff;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal.show {
  display: block;
}

.modalContent {
  margin: 15% auto;
  padding: 20px;
  border: 1px solid;
  width: 80%;
  background-color: #fefefe;
}

.modalCloseButton {
  float: right;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}

.modalContent.infoModal {
  min-height: 135px;
}

.modalCloseButton:hover,
.modalCloseButton:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
