#scheduleHeader {
  flex-shrink: 0;
  padding-bottom: 0.33em;
  padding-top: 0.33em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  background-color: hsl(
    var(--global-color-hue, 213),
    calc(var(--global-color-saturation, 80%) * 0.5875),
    calc((100% + var(--global-color-value, 34%)) / 2)
  );
}

#chanHeader {
  text-align: left;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  display: inline-flex
}

#schedembedBackButton {
  display: inline-block;
  padding: 0.5em;
  padding-right: 0;
  width: 1.2em;
  cursor: pointer;
}

#chanLabel,
#chanViews {
  margin-left: 0.5em;
  margin-right: 0.67em;
  flex-shrink: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#chanLabel {
  color: black;
  font-weight: bold;
}

#chanViews {
  margin-left: 0.67em;
}

#listPlayButton {
  vertical-align: middle;
  padding: 0.33em;
  font-size: 1em;
  background-color: #ddd;
  border-radius: 0.3em;
  color: black;
}

#listPlayButton:hover {
  background-color: #fff;
}

#playText {
  margin: 0;
}

#dateHeader {
  text-align: right;
  margin: auto 0.5em auto 0;
  display: inline-block;
}

.scheduleButtonPlaceholder {
  display: inline-block;
  width: 1em;
}

#scheduleBackButton,
#scheduleForwardButton {
  padding: 0;
  vertical-align: middle;
  padding-bottom: 0.2em;
  width: 1em;
  color: black;
  background: none;
  border: none;
}

#scheduleBackButton:hover,
#scheduleForwardButton:hover {
  cursor: pointer;
}

#scheduleHeaderLabel {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: 11em;
  text-align: center;
  font-size: 1.2em;
}

#scheduleHeaderLabel.schedule {
  width: 7.5em;
}

@media(max-width:599px) {
  #playText {
    display: none;
  }
  #scheduleHeaderLabel {
    width: 7.5em;
  }
  #listPlayButton {
    padding-left: 0.67em;
    padding-right: 0.67em;
  }
}
