label.shareLabel {
  margin-top: 1em;
  margin-bottom: 0.3em;
  display: block;
}

label.shareInnerLabel {
  margin: 0 0.5em;
}

#shareModalDirect,
#shareModalOld,
#shareModalEmbed {
  display: flex;
}

input.shareText {
  flex-grow: 1;
}

button.shareBtn {
  padding: 3px;
  border: 1px buttonface outset;
}
