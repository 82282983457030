#video-info-logo {
  flex-grow: 0;
  max-height: 49px;
  width: auto;
  height: auto;
  display: inline-block;
  margin: auto 5px auto 5px;
}

#video-info-logo:hover {
  cursor: pointer;
}
