#video-info {
  overflow: auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-grow: 2;
  width: 0;
  padding: 3px 0 3px 0;
  background-color: hsl(
    var(--global-color-hue, 213),
    calc(var(--global-color-saturation, 80%) - 1%),
    calc(var(--global-color-value, 34%) - 3%)
  );
  border-bottom: solid 1px black;
}

.SocialMediaShareButton {
  display: inline-block;
  margin: auto 3px;
}

.SocialMediaShareButton:hover {
  cursor: pointer;
}

.SocialMediaShareButton.disabled {
  opacity: 0.6;
}

.SocialMediaShareButton.disabled:hover {
  cursor: default;
}

.socialIcon {
  width: 32px;
  height: 32px;
  margin: auto;
}

@media(max-width:599px) {
  #video-info {
    width: initial;
  }
}
