#video-agenda-title {
  font-size: large;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: hsl(
    var(--global-color-hue, 213),
    calc(var(--global-color-saturation, 80%) - 1%),
    calc(var(--global-color-value, 34%) - 3%)
  );
  color: white;
}
