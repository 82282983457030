#channelTabs {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.2em;
  overflow-y: auto;
  max-width: 7.5em;
  background-color: hsl(
    var(--global-color-hue, 213),
    calc(var(--global-color-saturation, 80%) + 2%),
    calc(var(--global-color-value, 34%) + 8%)
  );
  border: 2px solid black;
}

@media(max-width:599px) {
  #channelTabs {
    white-space: nowrap;
    overflow-y: initial;
    overflow-x: auto;
    max-width: initial;
  }
}
