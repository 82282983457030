#browsing-menu {
  flex-grow: 1;
  width: 0;
  display: inline-flex;
  flex-direction: column;
  min-width: 17.4em;
}

#browsing-menu-button-section {
  overflow:auto;
  display:flex;
  align-items:stretch;
  flex-direction:row;
  flex-grow: 1;
}

@media(max-width:599px) {
  #browsing-menu {
    width: initial;
  }
}
