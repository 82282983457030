.browsing-menu-button {
  display: inline-flex;
  width: 100px;
  flex-grow: 2;
  padding: 0 2px 0 2px;
}

.browsing-menu-button > span {
  margin-top: 5px;
  margin-bottom: 5px;
}

button.browsing-menu-button,
button.browsing-menu-button:active,
button.browsing-menu-button:focus {
  border: solid 1px black;
  background-color: hsl(
    var(--global-color-hue, 213),
    calc(var(--global-color-saturation, 80%) - 1%),
    calc(var(--global-color-value, 34%) - 9%)
  );
  color: white;
  font-size: large;
  outline: none;
}

button.browsing-menu-button.selected,
button.browsing-menu-button.selected:active,
button.browsing-menu-button.selected:focus {
  background-color: hsl(
    var(--global-color-hue, 213),
    calc(var(--global-color-saturation, 80%) + 2%),
    calc(var(--global-color-value, 34%) + 8%)
  );
  border-bottom: none;
}

@media(max-width:599px) {
  .browsing-menu-button {
    width: 80px;
  }
  .browsing-menu-button > span {
    font-size: initial;
  }
}
