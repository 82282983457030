.toggleButton {
  padding: 8px;
  border-width: 1px;
  background-color: #ccd;
  color: #444;
  border-radius: 8px;
  border-color: #99a;
  border-style: solid;
}

.toggleButton:active:focus {
  padding: 9px 8px 7px 8px;
  background-color: #99a;
}

button.toggleButton::-moz-focus-inner {
  border: 0;
}

.toggleButton .toggleButtonIndicator {
  margin-right: 8px;
  vertical-align: middle;
  color: #333;
  font-size: large;
  font-weight: bold;
}

.toggleButton.on .toggleButtonIndicator {
  color: blue;
  text-shadow: 0 0 1px blue;
}

.toggleButton .toggleButtonLabel {
  font-weight: bold;
}
