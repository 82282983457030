#backButton {
  display: inline-block;
  padding: 0.5em;
  padding-right: 0;
  width: 1.2em;
  cursor: pointer;
}

#browsing-label-area {
  position: relative;
  background-color: hsl(
    var(--global-color-hue, 213),
    calc(var(--global-color-saturation, 80%) + 2%),
    calc(var(--global-color-value, 34%) + 8%)
  );
  color: white;
  border-bottom: solid 2px black;
}

#browsing-label {
  display: inline-block;
  padding: 0.5em;
}
