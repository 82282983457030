#browsing-section {
  height: 40%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: -webkit-linear-gradient(#f2f2f2, #b7b7b7);
  background: -o-linear-gradient(#f2f2f2, #b7b7b7);
  background: -moz-linear-gradient(#f2f2f2, #b7b7b7);
  background: linear-gradient(#f2f2f2, #b7b7b7);
  background-color: #d5d5d5;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

#browsing-section-hidden {
  display: none;
}
@media(min-aspect-ratio: 3/2) {
  #browsing-section.schedule {
    height: 100%;
    min-width: 26.4em;
    width: 0;
  }
}

#browsing-section.schedule {
  flex-grow: 1;
}

#browsing-section-hidden.schedule {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  flex-grow: 1;
  min-width: 26.4em;
  width: 0;
}

#browsing-items {
  flex-grow: 1;
}

#browsing-items-container {
  overflow-y: auto;
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#queueControls {
  padding: 0.5em;
}

#browsing-spinner {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
}

#browsing-spinner.hidden {
  display: none;
}

@media(max-width:599px) {
  #browsing-section {
    flex-grow: 1;
  }
  #browsing-section-hidden.schedule {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    flex-grow: 1;
    min-width: 26.4em;
    width: 0;
  }
}
