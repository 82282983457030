#video-area {
  display: flex;
  flex-grow: 2;
  flex-basis: 0;
  height: 50%;
  overflow: hidden;
}

@media(min-aspect-ratio: 3/2) {
  #video-area.schedule {
    height: 100%;
    flex-grow: 1;
  }
}

#video-area.schedule.hidden {
  display: none;
}

#info-bar {
  display: flex;
}

@media(max-width:599px) {
  #info-bar {
    flex-direction: column;
  }
  #video-area.hidden {
    display: none;
  }
}
