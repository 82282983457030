#searchBar {
  display: flex;
  padding: 2px;
  flex-shrink: 0;
  border: black 2px solid;
  background-color: white;
}

#searchBarInput {
  flex-grow: 1;
  border: none;
}

#searchBarButton {
  height: 1.5em;
  background: none;
  border: none;
}

#searchBarButton:hover {
  cursor: pointer;
}
