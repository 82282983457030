.scheduleItem {
  display: table-row;
  background-color: #bdbbbc;
  color: black;
}

.scheduleItem:last-child {
  border-right: none;
}

.scheduleItem:hover {
  cursor: pointer;
  background-color: #eee;
}

.scheduleItem:active:focus {
  background-color: #fff;
}

.scheduleItem.unavailable {
  background-color: #6d6e72;
  color: #181818;
}

.scheduleItem.playNowOff:hover,
.scheduleItem.playNowOff:active:focus{
  cursor: default;
}

.scheduleItem.unavailable:hover,
.scheduleItem.unavailable:active:focus{
  background-color: #6d6e72;
  cursor: default;
}

.scheduleItemName,
.scheduleItemStart,
.scheduleItemEnd,
.scheduleItemClass {
  display: table-cell;
  padding: 3px 0.5em;
  border-right: 1px black solid;
  border-bottom: 1px black solid;
}

.scheduleItemName {
  width: 99%;
}

.scheduleItemStart,
.scheduleItemEnd,
.scheduleItemClass {
  white-space: nowrap;
}
