#info-views {
  margin-top: 0.5em;
}

#info-thumb-container {
  width: 240px;
  height: 135px;
  position: relative;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

#info-thumb {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

#info-thumb-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  visibility: hidden;
  background-color: rgba(0,0,0,0.4);
}

#info-thumb-container:hover {
  cursor: pointer;
}

#info-thumb-container:hover > #info-thumb-cover {
  visibility: visible;
}

#info-thumb-cover-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: xx-large;
  color: #777;
  -webkit-text-fill-color: black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  -moz-text-stroke: 1px white;
}

#info-modal-section-wrapper {
  display: flex;
  flex-direction: row;
}

.info-modal-section {
  white-space: normal;
  display: inline-block;
  margin-left: 1em;
  margin-right: 1em;
  vertical-align: top;
  flex-shrink: 2;
}

#info-modal-section-table {
  flex-shrink: 1;
}

.info-row {
  display: table-row;
}

.info-text {
  display: table-cell;
  padding: 0.5em;
  padding-top: 0;
}

/* FOR MOBILE */
@media(max-width: 750px) {
  #info-modal-section-wrapper {
    flex-direction: column;
  }
  .info-modal-section {
    margin: 0;
  }
  #info-modal-section-table {
    margin-top: 1em;
  }
}
