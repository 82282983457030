ul#video-controls {
  background-color: black;
  list-style-type: none;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  max-height: 3em;
  transition: 0.5s max-height ease-out;
}

ul#video-controls.hidden {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s max-height ease-in;
}

li.video-control-btn:first-child {
  margin-left: 0;
}

li.video-control-btn,
li.video-control-volume,
li.video-control-volume button,
li.video-control-progress,
li.video-control-btn button {
  color: white;
}

li.video-control-btn,
li.video-control-progress,
li.video-control-volume {
  float: left;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  margin-left: 0.7em;
}

li.video-control-progress {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
}

li.video-control-volume {
  position: relative;
}

li.video-chrome-btn {
  max-width: 2.5em;
  max-height: 2.5em;
  margin-left: 0.7em;
}

#video-progress-container {
  flex-grow: 1;
  display: flex;
  height: 100%;
  position: relative;
}

#video-progress-container:active {
  cursor: pointer;
}

@-webkit-keyframes liveanimation {
    0% {background-position: 0 0}
    100% {background-position: 100% 0}
}

#video-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border: none;

  flex-grow: 1;
  height: 6px;
  margin: auto;
  width: 0;

  background-color: grey;
  color: white;
}

#video-progress.live {
  background-color: none;
  animation: 20s liveanimation infinite linear;
  background-image: repeating-linear-gradient(-45deg, grey, grey 5%, white 5%, white 10%);
  background-size: 200% 100%;
}

#video-progress-dot {
  background-color: white;
  position: absolute;
  width: 1em;
  height: 1em;
  top: calc(50% - 0.5em);
  border-radius: 1em;
  cursor: pointer;
}

progress#video-progress::-moz-progress-bar {
  background-color: white;
}

progress#video-progress::-webkit-progress-value {
  background-color: white;
}

progress#video-progress.live::-webkit-progress-bar {
  background-color: transparent;
}

span#video-progress-label {
  margin-left: 1em;
}

div#video-progress-popup {
  display: none;
}

li.video-control-btn button,
li.video-control-volume button {
  width: 2.5em;
  height: 2.5em;
}

li.video-control-btn > google-cast-launcher {
  --disconnected-color: grey;
  --connected-color: white;
}

.control-btn-icon {
  max-width: 100%;
  max-height: 100%;
  width: 2em;
  height: 2em;
  pointer-events: none;
}

button#video-captions > svg path {
  fill: #aaa;
}

button#video-captions > svg.showing path {
  fill: #fff;
}

button#video-fullscreen span {
  font-size: 36px;
  line-height: 80%;
  font-weight: bold;
}

div#video-volume-formatter {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

div#video-volume-tooltip {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  visibility: hidden;
  transform: rotate(-90deg);
}

#video-volume-tooltip.show {
  visibility: visible;
}

div#video-volume-tooltip span {
  background-color: black;
  border: solid 1px grey;
  border-radius: 3px;
  padding: 3px;
}

input#video-volume {
  -webkit-appearance: none;
  margin: 9px 0 9px 0;
  font-size: 16px;
  border: 0;
  padding: 0;
  width: 12em;
  height: 1.3em;
  background-color: rgba(0, 0, 0, 0.6);
  vertical-align: middle;
}

input#video-volume::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.2em;
  box-sizing: border-box;
  background-color: rgb(165, 165, 165);
  border: 0;
}

input#video-volume::-moz-range-track {
  width: 100%;
  height: 0.2em;
  box-sizing: border-box;
  background-color: rgb(165, 165, 165);
  border: 0;
}

input#video-volume::-ms-track {
  width: 100%;
  height: 0.2em;
  box-sizing: border-box;
  background-color: rgb(165, 165, 165);
  border: 0;
}

input#video-volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 1em;
  width: 1em;
  border-radius: 0.5em;
  border-width: 0;
  margin-top: -0.35em;
  background-color: white;
}

input#video-volume::-moz-range-thumb {
  box-sizing: border-box;
  height: 1em;
  width: 1em;
  border-radius: 0.5em;
  border-width: 0;
  background-color: white;
}

input#video-volume::-ms-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 1em;
  width: 1em;
  border-radius: 0.5em;
  border-width: 0;
  margin-top: 0;
  background-color: white;
}

input#video-volume::-ms-ticks-before,
input#video-volume::-ms-ticks-after,
input#video-volume::-ms-tooltip {
  display: none;
}

@media(max-width:599px) {
  ul#video-controls {
    padding-left: 10px;
    padding-right: 10px;
  }
  #video-progress-label {
    display: none;
  }
  #video-progress-popup.mobileShow {
    display: block;
    position: absolute;
    white-space: nowrap;
    top: -150%;
    transform: translateX(calc(-50% + 0.5em));
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.6);
  }
  li.video-control-btn,
  li.video-control-volume {
    margin-left: 0.3em;
  }
  li.video-control-progress {
    margin-right: 0.4em;
  }
  li.video-control-btn button,
  li.video-control-volume button {
    max-width: 8vw;
    max-height: 8vw;
  }
}
