.paginatorDiv {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  border-top: 1px black solid;
  background-color: #999;
}
.paginatorDiv > button {
  width: 32px;
  height: 32px;
}

.paginatorDiv > button:hover {
  background-color: #bbb;
}
